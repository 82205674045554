<template>
  <div class="parkingList">
    <div class="header">
      <div class="searchBox">
        <van-search
          v-model="filterText"
          placeholder="license plate number"
          showAction
          background="#e8e8e8"
          @cancel="getList"
          @search="getList"
          @clear="clearCallback"
        >
          <template slot="action">
            <span style="color: #6c6c6c" @click="getList">Search</span>
          </template>
        </van-search>
      </div>
      <div class="filterTime">
        <div
          :class="['filterItem', currentFilter === item ? 'filterItemSel' : '']"
          v-for="(item, index) in filterTime"
          :key="index"
          @click="changeCurrent(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <!-- <div style="height: 120px"></div> -->

    <div
      class="list"
      @scroll="handleScroll"
      ref="listRef"
      v-if="list && list.length"
    >
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        :ref="`anchor-${index}`"
        @click="toggleContent(index, item.showContent)"
      >
        <div class="times row-between">
          <div class="omit">{{ item.license }} / {{ item.make }}</div>
          <div class="">
            {{ item.num }} {{ item.mobileChargeName
            }}{{ item.num > 1 ? "s" : "" }}
            <span v-if="item.showTime">- {{ item.toTime }}</span>
          </div>
        </div>
        <div
          class="centent"
          :style="{ maxHeight: item.showContent ? '52px' : '0px' }"
        >
          <div class="info">
            <div class="date">
              {{ item.fromDate | filterDate }} {{ item.fromTime }} ~
              {{ item.toDate | filterDate }} {{ item.toTime }}
            </div>
            <div class="row-between">
              <div class="omit flex1">
                {{ item.name }}
              </div>
              <div class="price">$ {{ item.total }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
          class="item"
          v-for="(item, index) in 10"
          :key="item"
          style="height: 100px"
          :ref="`anchor-${index + 2}`"
        ></div> -->
    </div>

    <div class="alphabeticList">
      <div
        :class="[
          'alphabeticItem',
          currentAlp === item ? 'alphabeticItem_sel' : '',
        ]"
        v-for="(item, index) in alphabetic"
        :key="index"
        @click="anchor(item)"
      >
        {{ item }}
      </div>
    </div>

    <div v-if="!list || !list.length" style="margin-top: 30px">
      <van-empty description="Not Data" image="search" />
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapActions } from "vuex";
import { postAction, getAction } from "@/api/manage";
import { Toast } from "vant";
export default {
  data() {
    return {
      filterText: "",
      filterTime: ["Yesterday", "Today", "Tomorrow"],
      currentFilter: "Today",
      list: [],
      alphabetic: [],
      currentAlp: "",
      hotelDeptName: "roomserviceqr",
      password: "654321",
      hotelName: "",
      hotelId: "",
    };
  },
  created() {
    if (this.$route.query.hotelname) {
      this.hotelName = this.$route.query.hotelname;
      this.login();
    }
  },
  filters: {
    filterDate(date) {
      return moment(date).format("MM/DD");
    },
  },
  methods: {
    moment,
    ...mapActions(["editColor"]),
    clearCallback() {
      this.filterText = "";
      this.getList();
    },
    login() {
      postAction("/api/common/user/hotel_dept_name/login", {
        hotelDeptName: this.hotelDeptName,
        hotelName: this.hotelName,
        password: this.password,
      })
        .then((res) => {
          if (res.msg == "Login success") {
            localStorage.setItem("HTTOKEN", res.data.token);
            this.getUserInfo();
          } else {
            Toast({
              message: `Invalid RoomServiceQR access`,
              duration: 3000,
              className: "toast",
            });
          }
        })
        .catch((err) => {
          Toast({
            message: `Invalid RoomServiceQR access`,
            duration: 3000,
            className: "toast",
          });
        });
    },
    getUserInfo() {
      getAction("/api/common/user/current_user").then((res) => {
        this.hotelId = res.data.hotelId;
        this.getHotelInfo();
        this.getList();
      });
    },
    getHotelInfo() {
      getAction("/adminApi/hotelinfo/infobyhotel", {
        hotelId: this.hotelId,
      }).then((res) => {
        if (this.$route.query.use_sign_color) {
          this.editColor("#" + this.$route.query.use_sign_color);
        } else {
          this.editColor("#" + res.data.color);
        }
      });
    },
    toggleContent(index, showContent) {
      this.$set(this.list[index], "showContent", !showContent);
    },
    async getList() {
      let fromDate, toDate;
      // try {
      let date = moment
        .tz(localStorage.getItem("timeZone"))
        .format("YYYY-MM-DD");
      if (this.currentFilter === "Today") {
        fromDate = date;
        toDate = date;
      }
      if (this.currentFilter === "Yesterday") {
        fromDate = moment
          .tz(date, localStorage.getItem("timeZone"))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        toDate = moment
          .tz(date, localStorage.getItem("timeZone"))
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      }
      if (this.currentFilter === "Tomorrow") {
        fromDate = moment
          .tz(date, localStorage.getItem("timeZone"))
          .add(1, "days")
          .format("YYYY-MM-DD");
        toDate = moment
          .tz(date, localStorage.getItem("timeZone"))
          .add(1, "days")
          .format("YYYY-MM-DD");
      }

      //   Toast("success");
      // } catch (error) {
      //   Toast(JSON.stringify(error));
      // }

      const { data } = await postAction(
        "/api/common/hotel/data/getParkingOrder",
        {
          hotelId: this.hotelId,
          fromDate,
          toDate,
          license: this.filterText,
        }
      );

      let alphabetic = [];
      data.forEach((item) => {
        let initial = item.license.charAt(0).toUpperCase();
        if (alphabetic.findIndex((item) => item === initial) === -1) {
          alphabetic.push(initial);
        }
      });
      this.alphabetic = alphabetic.sort();
      this.currentAlp = alphabetic[0];

      let list = [];
      alphabetic.forEach((item) => {
        data.forEach((dataItem) => {
          if (dataItem.license.charAt(0).toUpperCase() === item) {
            list.push({
              ...dataItem,
              showContent: false,
              showTime: dataItem.toDate === toDate ? true : false,
            });
          }
        });
      });
      this.list = list;
    },

    changeCurrent(cur) {
      this.currentFilter = cur;
      this.getList();
    },

    anchor(val) {
      Toast(val);
      this.currentAlp = val;
      let index = this.list.findIndex(
        (item) => item.license.charAt(0).toUpperCase() === val
      );
      this.$refs[`anchor-${index}`][0].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // 滚动监听器
    handleScroll(i) {
      const navContents = document.querySelectorAll(".item");
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop - 118);
      });
      // console.log("offsetTopArr===============", offsetTopArr);
      // 获取当前文档流的 scrollTop
      const scrollTop = this.$refs.listRef.scrollTop;
      // console.log("scrollTop===============", scrollTop);
      offsetTopArr.forEach((item, index) => {
        if (scrollTop >= item) {
          this.currentAlp = this.list[index].license.charAt(0).toUpperCase();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.parkingList {
  // position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  width: 100%;
  padding: 16px 26px 14px;
  height: 118px;
  // background-color: #f5f4f4;
  // height: 140px;
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 99;
}

.searchBox {
  padding: 0 10px;
  margin: 0 auto;

  /deep/.van-search {
    border-radius: 6px;
    padding: 4px 10px;
    border: 1px solid #bbbbbb;

    .van-cell {
      padding: 2px 8px 2px 0;
    }

    .van-search__content {
      background-color: #fff;
    }
  }
}

.filterTime {
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filterItem {
    background-color: #fff;
    font-size: 14px;
    color: #333;
    text-align: center;
    height: 30px;
    line-height: 30px;
    width: 30%;
    border-radius: 4px;
    border: 1px solid #bbbbbb;
  }

  .filterItemSel {
    background-color: $primary-color;
    color: #fff;
  }
}

.list {
  padding: 0 26px;
  // height: calc(100vh-600px);
  overflow-y: scroll;
  scrollbar-width: none;
  flex: 1;
  // background-color: pink;
  // padding-bottom: 40px;
  // position: absolute;
  // padding-top: 120px;
  // height: 100%;
  // width: 100%;

  .item {
    padding: 10px 16px 10px 24px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #bbbbbb;
    margin-bottom: 14px;

    .times {
      div:first-of-type {
        font-size: 16px;
        font-weight: bold;
        color: #2c58f4;
        text-decoration: underline;
        text-align: left;
        flex: 1;
      }

      div:last-of-type {
        font-size: 16px;
        color: #101010;
        font-weight: bold;
      }
    }

    .centent {
      padding-left: 14px;
      transition: max-height 0.3s;
      overflow: hidden;

      .info {
        .date {
          font-size: 14px;
          color: #101010;
          text-align: left;
          padding-top: 10px;
        }

        div:last-of-type {
          font-size: 14px;
          color: #101010;
          text-align: left;

          .price {
            color: #dd0000;
            font-weight: bold;
            font-size: 16px;
            flex-shrink: 0;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.alphabeticList {
  position: absolute;
  left: 8px;
  top: 120px;
  z-index: 99;

  .alphabeticItem {
    font-size: 14px;
    color: #343434;
    font-weight: bold;
    margin-top: 4px;
    cursor: pointer;
  }

  .alphabeticItem_sel {
    color: #2c58f4;
  }
}
</style>
